import styled from 'styled-components';
import bannerVideo from '../video/SGHack Hero.mp4'
import { useState } from "react";
import importAllImg from "../helpers/import-all-imgs";
import { message } from 'antd';
const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));

function handleResponse(response) {

  return response
    .json()
    .then((res) => {
      const data = res;
      if (!response.ok) {
        const error =
          (data && (data.error || data.msg))
            ? (data.error || data.msg)
            : "Error :Bad Request";
        return Promise.reject(error);
      }
      return data;
    })
    .catch((err) => {
      console.log("error", err);
      throw err;
    });
}

function contactUs(params) {
  const url = `https://api-t.udty.tech/api/v3/common/sgHackRegister`;
  const myHeaders = new Headers();
  myHeaders.append("content-type", "application/json");

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(params),
  };

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch(function (err) {
      throw err;
    });
}

function Home2024() {

  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const items = [
    // {
    //   title: 'SGHACK OnTap Event',
    //   url: 'https://sghack.regfox.com/sghack-ontap',
    //   btn: 'REGISTER NOW →',
    //   month: 'March'
    // },
    // {
    //   title: 'WhiskeyCon Call for Speakers',
    //   url: 'https://sghack.regfox.com/sghack-call-for-papers',
    //   btn: 'SUBMIT PROPOSAL →',
    //   month: 'September'
    // },
    {
      title: 'SGHACK Conference and WhiskeyCon event',
      url: 'https://sghack.regfox.com/sghack-reg',
      btn: 'Event Details →',
      month: 'September'
    },
  ];


  const isMobile = window.innerWidth < 991;

  const submit = async (e) => {
    e.preventDefault();

    const params = {
      email,
      firstName,
      lastName,
      phoneNumber
    }
    contactUs(params).then(res => {
      if (res.code !== 200) {
        message.error(res.msg);
      } else {
        message.success('success');
      }
    }, rej => message.error(JSON.stringify(rej)));
  }
  return (
    <Container>
      <div className='banner'>
        <video src={bannerVideo} autoPlay muted loop playsInline>
        </video>
        {/*<img src={ isMobile ? imgs['fabio-oyXis2kALVg-unsplash 2.png']: imgs['fabio-oyXis2kALVg-unsplash 1.png']} />*/}
      </div>

      <div className='up-events show-desktop' id='up-events'>
        <div className='up-events-title'>UPCOMING EVENTS</div>
        <div className='up-events-tips'>Click below to learn more, and register for each event.</div>
        <div className='up-events-items'>
          {/*<div>*/}
          {/*  <div className='up-events-item-date'>*/}
          {/*    <span>13</span>*/}
          {/*    September*/}
          {/*  </div>*/}
          {/*  <div className='up-events-item-title'>WhiskeyCon Call for Speakers </div>*/}
          {/*  <div className='up-events-item-content'>*/}
          {/*    WhiskeyCon 2024 is an exclusive, one-night only event to be held on 13th September in Singapore. Security professionals from all over the world will gather for an evening of food and drink to share with, and learn from, each other.*/}
          {/*  </div>*/}
          {/*  <div className='up-events-item-learn-more'>Learn More</div>*/}
          {/*  <div className='up-events-item-btn'>SUBMIT PROPOSAL</div>*/}
          {/*</div>*/}

          <div>
            <div className='up-events-item-date'>
              <span>13</span>
              September
            </div>
            <div className='up-events-item-title'>SGHACK Conference and WhiskeyCon event </div>
            <div className='up-events-item-content'>
              Singapore's premier cyber security training event! SgHACK, in partnership with WhiskeyCon, brings you unparralled hands on training for the most discerning cyber security professional.
            </div>
            <div onClick={() => window.open('https://sghack.regfox.com/sghack-reg')} className='up-events-item-learn-more'>Learn More</div>
            <div onClick={() => window.open('https://sghack.regfox.com/sghack-reg')} className='up-events-item-btn'>Event Details</div>
          </div>
        </div>
      </div>

      <div className='up-events show-mobile' id='up-events'>
        <div className='up-events-title'>UPCOMING EVENTS</div>
        <div className='up-events-tips'>Click below to learn more, and register for, each event.</div>
        <div className='up-events-content'>
          <img src={imgs['image 15.png']} />
          <div className='up-events-content-items'>
            {
              items.map(i => (
                <div key={i.title} className='up-events-content-item'>
                  <div>
                    <span>13</span>
                    <span>{i.month}</span>
                  </div>
                  <div>
                    <div>{i.title}</div>
                    <div onClick={() => window.open('https://sghack.regfox.com/sghack-reg')} className='mobile-lean-more'>Learn More</div>
                    <div onClick={() => window.open(i.url)}>{i.btn}</div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <div className='events' id='about'>
        <div className='events-title'>
          OUR <span>EVENTS</span>
        </div>
        <div className='events-content'>
          <div>
            <img src={imgs['Group 2965.png']} />
            <div>
              Casual gatherings throughout the year at various bars throughout Singapore for like-minded security professionals to, over drinks and snacks, chat about hacks. Sometimes current events justify an impromptu gathering at a local bar and SgHACK OnTap is aimed at keeping us connected throughout the year, between more formal gatherings. Join our mailing list to keep in the know!
            </div>
          </div>

          <div>
            <img src={imgs['Group 2966 (1).png']} />
            <div>
              As the world experiences trying times, life and cyber security must go on. WhiskeyCon is here to help. Security professionals from all over the world gather for an exclusive, one-night event of food and drink to share with, and learn from, each other. The format is simple, drink 2 shots of whiskey for five minutes of speaking time. Need another 5 minutes? Drink 2 more shots. Anyone can speak, as long as you drink.
            </div>
          </div>
        </div>
      </div>


      {/*<div className='about' id='about'>*/}
      {/*  <div className='about-title'>ABOUT</div>*/}
      {/*  <img src={imgs['Group (2).svg']} />*/}
      {/*  <div className='about-text'>*/}
      {/*    SgHACK is a collective of like-minded security professionals who are involved with, or interested in, Singapore’s <br/> cybersecurity space. We work to bring people together through SgHACK OnTap happy hours, SgHACK training and <br/> conferences, and WhiskeyCon, our legendary exclusive, private event dating back to 2014. Register below to be notified <br/> of future events!*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className='touch-panel' id='touch'>
        <img src={imgs['image 15.png']} />
        <div className='touch-content'>
          <div className='get-touch-title'>GET IN TOUCH</div>
          <div className='touch-tip'>
            Sign up below to be emailed about future SgHACK, SgHACK OnTap, and WhiskeyCon events!
          </div>
          <form onSubmit={submit}>
            <div className='form-row'>
              <label>Name *</label>
              <div className='inputs'>
                <input onChange={e => setFirstName(e.target.value)} placeholder='First' required/>
                <input onChange={e => setLastName(e.target.value)}  placeholder='Last' required/>
              </div>
            </div>

            <div className='form-row'>
              <label>Email *</label>
              <input onChange={e => setEmail(e.target.value)}  placeholder='Enter Email Address' required type='email'/>
            </div>

            <div className='form-row'>
              <label>Mobile Phone Number</label>
              <input onChange={e => setPhoneNumber(e.target.value)}  placeholder='Enter Phone Number'/>
            </div>

            <div className='form-row'>
              <label>Agreement*</label>
              <div className='agreement-text'>
                <div>
                  <input type='checkbox' required/>
                  Click here to opt-in to emails about future events!
                </div>

                <div>
                  By submitting this form, you agree to us contacting you about SgHACK, SgHACK OnTap, and WhiskeyCon events. We will never share your details with any third party.
                </div>

                <div>
                  <input type='checkbox' required/>
                  I have read and agree to the terms above.
                </div>
              </div>
            </div>

            <div className='form-btn-panel'>
              <button type='submit'>SUBMIT</button>
            </div>
          </form>

          <div className='message-panel'>
            <img src={imgs['signal-svgrepo-com (1).png']} />
            <div>
              <div className='not-fan-text'>
                Not a fan of Email?  Message us on Signal
              </div>
              <a style={{background: '#3A76F0'}} className='email-btn' onClick={() => window.open('https://signal.me/#eu/zSTIpaVLVr_i767UQgBe3RxcbL0l6l8TllHR3xhWSbpxuMUJvcdOG472HQlpnTlE')}>
                MESSAGE US
              </a>
            </div>
          </div>
        </div>
      </div>



      {/*<div className='touch' id='touch'>*/}
      {/*  <div className='touch-title'>GET IN TOUCH</div>*/}
      {/*  <div className='touch-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-signup')}>JOIN MAILING LIST</div>*/}
      {/*  <div className='touch-items'>*/}
      {/*    <div>*/}
      {/*      <img src={imgs['email-9-svgrepo-com.svg']} />*/}
      {/*      <div className='touch-text'>*/}
      {/*        Questions? Email us at <br/>*/}
      {/*        <a href='mailto:contact@SgHACK.org'>contact@SgHACK.org</a>*/}
      {/*      </div>*/}
      {/*      <a className='email-btn' href='mailto:contact@SgHACK.org'>*/}
      {/*        EMAIL US*/}
      {/*      </a>*/}
      {/*    </div>*/}

      {/*    <div>*/}
      {/*      <img src={imgs['signal-svgrepo-com.png']} />*/}
      {/*      <div className='touch-text'>*/}
      {/*        Not a fan of Email?  Message { !isMobile && <br/> } us on Signal*/}
      {/*      </div>*/}
      {/*      <a style={{background: '#3A76F0'}} className='email-btn' onClick={() => window.open('https://signal.me/#p/+{65}{82994022}')}>*/}
      {/*        MESSAGE US*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Container>
  )
}

const Container = styled.div`
  
  .mobile-lean-more {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: #000000;
    text-align: right;
    //margin-right: 75px;
  }
  
  .show-desktop {
    @media (max-width: 991px) {
      display: none !important;
    }
  }
  
  .show-mobile {
    display: none !important;
    @media (max-width: 991px) {
      display: block !important;
    }
  }
  
  .not-fan-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 12px;
    }
  }
  
  .message-panel {
    display: flex;
    align-items: center;
    column-gap: 25px;
    margin-top: 15px;

    @media (max-width: 991px) {
      & > img {
        width: 45px;
      }
    }
  }
  
  .form-btn-panel {
    margin-left: 210px;
    @media (max-width: 991px) {
      margin-left: 0;
      display: flex;
      justify-content: flex-end;
      margin-top: -5px;
    }
    button {
      width: 195px;
      height: 50px;
      background: #C3272E;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #FFFFFF;
      border: none;
      outline: none;
      cursor: pointer;

      @media (max-width: 991px) {
        font-size: 11px;
        color: #FFFFFF;
        width: 101px;
        height: 28px;
      }
    }
  }
  
  .agreement-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    @media (max-width: 991px) {
      font-size: 11px;
      color: #FFFFFF;
    }
    
    & > div {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      @media (max-width: 991px) {
        column-gap: 5px;
      }
      
      input {
        flex: 0 0 36px;
        height: 36px;
        cursor: pointer;
        @media (max-width: 991px) {
          height: 15px;
          flex: 0 0 15px;
        }
      }
    }
  }
  
  .form-row {
    display: flex;
    column-gap: 15px;
    margin-bottom: 15px;
    label {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      min-width: 195px;
      @media (max-width: 991px) {
        font-size: 12px;
        min-width: 70px;
      }
    }

    .inputs {
      flex: 1;
      display: flex;
      align-items: center;
      column-gap: 20px;
      @media (max-width: 991px) {
        column-gap: 10px;
        
        & > input {
          width:  45%;
        }
      }
    }
    
    input {
      flex: 1;
      height: 40px;
      background: #FFFFFF;
      border-radius: 5px;
      padding-left: 10px;
      display: flex;
      align-items: center;
      border: none;
      outline: none;
      @media (max-width: 991px) {
        height: 23px;
      }
    }
  }
  
  .touch-tip {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
    margin-bottom: 5px;

    @media (max-width: 991px) {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .get-touch-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    color: #FFFFFF;
    margin-bottom: 10px;


    @media (max-width: 991px) {
      font-size: 30px;
      text-align: center;
    }

  }
  
  .touch-panel {
    position: relative;
    display: flex;
    align-items: center;
    background: #000000;
    padding: 150px 0;

    @media (max-width: 991px) {
      //display: none;
      padding: 10px 5%;
      flex-flow: column-reverse;
      overflow-x: hidden;
      
      & > img {
        width: 80%;
      }
    }
  }
  
  .touch-content {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    background: #000000;
    padding: 40px;
    z-index: 2;
    height: 85%;
    @media (max-width: 991px) {
      position: relative;
      width: 100%;
      height: auto;
      margin-top: -5px;
    }
  }
  
  .up-events-items {
    display: flex;
    column-gap: 20px;
    justify-content: center;
    & > div {
      flex: 0 0 48%;
    }
  }
  
  .up-events-item-btn {
    width: 240px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    background: #C3272E;
    border-radius: 10px;
  }
  
  .up-events-item-learn-more {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .up-events-item-content {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 30px;
    min-height: 108px;
  }
  
  .up-events-item-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    color: #FFFFFF;
    margin: 15px auto;
  }
  
  .up-events-item-date {
    font-family: 'Futura-Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    color: #C3272E;
    display: flex;
    align-items: flex-end;
    column-gap: 10px;
    padding-bottom: 15px;
    border-bottom: 2px solid #C3272E;

    span {
      font-size: 96px;
      
    }
  }
  
  @font-face {
    font-family: 'Futura-Bold';
    src: local('Futura Bold'), url('/fonts/Futura Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  .email-btn {
    width: 256px;
    height: 60px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
    background: #C3272E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    text-decoration: none;
    @media (max-width: 991px) {
      font-size: 11px;
      color: #FFFFFF;
      width: 101px;
      height: 28px;
    }
  }
  
  .touch-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
    }
    a {
      color: #000000 !important;
    }
  }
  .touch-items {
    display: flex;
    column-gap: 40px;
    justify-content: center;
    @media (max-width: 991px) {
      column-gap: 20px;
    }
    & > div {
      flex: 0 0 500px;
      height: 340px;
      background: #FFFFFF;
      border: 1px solid #909090;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 39px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      row-gap: 15px;
      @media (max-width: 991px) {
        flex: 0 0 160px;
        height: 184px;
        row-gap: 10px;
        border-radius: 20px;
        & > img {
          width: 47px;
        }
      }
    }
  }
  .touch-btn {
    width: 256px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    color: #FFFFFF;
    background: #C3272E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    cursor: pointer;
    @media (max-width: 991px) {
      width: 206px;
      height: 44px;
      font-size: 16px;
      line-height: 24px;
    }
      
  }
  
  .touch-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 62px;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 44px;
    }
  }
  
  .touch {
    padding: 50px;
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 20px;
    @media (max-width: 991px) {
      padding: 20px;
    }
  }
  
  .up-events-content-items {
    display: flex;
    flex-flow: column;
    row-gap: 25px;
    margin-left: -100px;
    @media (max-width: 991px) {
      align-items: center;
      margin-left: 0;
    }
  }
  .up-events-content-item {
    display: flex;
    background: #F2F2F7;
    border-radius: 25px;
    width: 653px;
    @media (max-width: 991px) {
      width: 90%;
    }
    & > div:nth-child(1) {
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      row-gap: 5px;
      background: #C3272E;
      flex: 0 0 137px;
      height: 125px;
      flex-flow: column;
      @media (max-width: 991px) {
        flex: 0 0 94px;
        height: auto;
        padding: 10px 0;
      }
      & > span:nth-child(1) {
        font-family: 'Futura-Bold';
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 62px;
        text-align: center;
        color: #FFFFFF;
        @media (max-width: 991px) {
          font-size: 36px;
          line-height: 41px;
        }
      }
      
      & > span:nth-child(2) {
        font-family: 'Futura-Bold';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #FFFFFF;
        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 16px;
        }
      }

    }
    
    & > div:nth-child(2) {
      flex: 1;
      padding: 10px;
      padding-left: 20px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      & > div:nth-child(1) {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 37px;
        color: #000000;
        text-align: left;
        @media (max-width: 991px) {
          font-size: 20px;
          line-height: 30px;
        }
      }
      & > div:nth-child(3) {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        text-align: right;
        color: #C3272E;
        cursor: pointer;
        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .up-events-content {
    display: flex;
    @media (max-width: 991px) {
      flex-flow: column-reverse;
      row-gap: 10px;
      align-items: center;
      & > img {
        width: 80%;
        display: none;
      }
    }
  }
  .up-events-tips {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 15px;
    }
  }
  
  .up-events-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 44px;
      margin-bottom: 15px;
    }
  }
  .up-events {
    padding: 50px;
    background: #1A0F15;
    @media (max-width: 991px) {
      padding: 20px;
      margin-top: -5px;
    }
  }
  .events-content {
    display: flex;
    justify-content: center;
    column-gap: 50px;
    @media (max-width: 991px) {
      flex-flow: column;
      column-gap: 0;
      align-items: center;
      row-gap: 15px;
      margin-top: -5px;
    }
    & > div {
      flex: 0 0 610px;
      height: 463px;
      background: #F2F2F7;
      border-radius: 39px;
      @media (max-width: 991px) {
        flex: 0 0 90%;
        & > img {
          width: 100%;
        }
      }
      & > div {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        padding: 20px 35px;

        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .events-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
    span {
      color: #C3272E;
    }
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 44px;
    }
  }
  
  .events {
    padding: 50px;
    background: #1A0D16;
    @media (max-width: 991px) {
      padding: 20px;
    }
  }
  .about-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  .about-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 62px;
    text-align: center;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 44px;
    }
  }
  
  .about {
    background: #F2F2F7;
    padding: 50px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    @media (max-width: 991px) {
      padding: 20px;
      row-gap: 10px;
      & > img {
        height: 41px;
      }
    }
  }
  
  .banner {
    background: #000000;
    display: flex;
    justify-content: center;
    & > img {
      width: 100%;
      object-fit: cover;
    }
    & > video {
      width: 100%;
    }
    @media (max-width: 991px) {
      padding: 20px;
      & > video {
        width: 100%;
      }
    }
  }

`

export default Home2024;
