import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Whiskeycon from "./pages/Whiskeycon";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Course1 from "./pages/Course1";
import Course2 from "./pages/Course2";
import Course3 from "./pages/Course3";
import Course4 from "./pages/Course4";
import Terms from "./pages/Terms";
import Home2024 from "./pages/Home2024";


function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          {/*<Route path='/' exact element={<Home />}/>*/}
          {/*<Route path='/whiskeycon' exact element={<Whiskeycon />}/>*/}
          {/*<Route path='/course1' exact element={<Course1 />}/>*/}
          {/*<Route path='/course2' exact element={<Course2 />}/>*/}
          {/*<Route path='/course3' exact element={<Course3 />}/>*/}
          {/*<Route path='/course4' exact element={<Course4 />}/>*/}
          {/*<Route path='/' exact element={<Whiskeycon />}/>*/}
          <Route path='/' exact element={<Home2024 />}/>
          <Route path='/terms' exact element={<Terms />}/>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
