import styled from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import { useNavigate } from "react-router-dom";
import useToTop from "../helpers/useToTop";

const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));
const Container = styled.div`
  
  .banner {
    background-image: url("${imgs['pexels-addie-3152126 1.png']}");
    background-size: cover;
    background-repeat: no-repeat;
    height: 610px;
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
    @media (max-width: 991px) {
      background-image: url("${imgs['Group 2932.png']}");
      height: 250px;
      margin-top: 70px;
    }
    
  }
  
  .banner-text {
    margin-top: 96px;
    margin-right: 10%;
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }
  
  .banner-title {
    font-weight: 400;
    font-size: 64px;
    line-height: 62px;
    text-align: right;
    color: #FFFFFF;
    margin-bottom: 50px;
    font-family: 'Fugaz One';
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 10px;
    }
  }
  
  .banner-date {
    font-weight: 400;
    font-size: 32px;
    line-height: 47px;
    text-align: right;
    color: #FFC600;
    margin-bottom: 32px;
    font-family: 'Fugaz One';
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 29px;
      margin-bottom: 10px;
    }
  }
  
  .register-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    width: 222px;
    height: 51px;
    background: #C3272E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-left: auto;
    @media (max-width: 991px) {
      width: 170px;
      height: 39px;
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  .about-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 75px 10% 75px 15%;
    position: relative;
    @media (max-width: 991px) {
      flex-flow: column;
      padding: 30px 10%;
    }
  }
  
  #about {
    position: absolute;
    top: -100px;
    @media (max-width: 991px) {
      top: -70px;
    }
  }
  
  .about-right {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #909090;
    }
  }
  
  .about-left-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    color: #000000;
    margin-bottom: 5px;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 1.2;
    }
  }
  
  .about-left-date {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #FFC600;
    margin: 14px 0 5px;
    font-family: 'Fugaz One';
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      magin-top: 5px;
    }
  }
  
  .about-left-location {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #909090;

    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 15px;
      text-align: center;
    }
  }
  
  .about-left-img {
    margin-left: -10px;
  }
  
  .whiskeyCon-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 10% 80px 15%;
    background: #19191A;
    @media (max-width: 991px) {
      flex-flow: column;
      padding: 25px 10%;
    }
  }
  
  .whiskeyCon-right-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    margin-bottom: 28px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 10px;
    }
  }
  
  .whiskeyCon-right-btn {
    width: 222px;
    height: 51px;
    background: #C3272E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Titillium Web';
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;

    @media (max-width: 991px) {
      width: 170px;
      height: 39px;
      font-size: 14px;
      line-height: 21px;
      margin-left: auto;
      margin-right: auto;
    }

  }
  
  .schedule-panel {
    padding: 80px 10% 80px 15%;
    background: #F5F5F5;
    position: relative;
    @media (max-width: 991px) {
      padding: 30px 10%;
      padding-bottom: 0;
    }
  }
  
  #schedule {
    position: absolute;
    top: -100px;
    @media (max-width: 991px) {
      top: -70px;
    }
  }
  
  .schedule-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
    & > span {
      color: #C3272E;
    }

    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
  
  .schedule-date {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 47px;
    /* identical to box height */

    text-align: center;

    color: #C3272E;
    margin-bottom: 40px;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 28px;
    }
  }
  
  .day-panel {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    & > span {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45%;
      height: 52px;
      background: #C3272E;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      font-family: 'Fugaz One';
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 47px;
      text-align: center;

      color: #FFFFFF;
    }
  }
  
  .courses {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    & > span {
      width: 45%;
      display: block;
      text-align: center;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 49px;
      text-transform: uppercase;

      color: #000000;
    }
  }
  
  .list-item {
    display: flex;
    margin-bottom: 60px;
    & > img {
      width: 200px;
      height: 200px;
      border-radius: 20px;
      margin-right: 48px;
      @media (max-width: 991px) {
        width: 100px;
        height: 100px;
        margin-right: 17px;
        border-radius: 10px;
      }
    }
  }
  
  .item-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 18px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
  
  .item-writer {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 45px;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 15px;
      margin-bottom: 10px;
    }
  }
  
  .item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 222px;
    height: 51px;
    background: #FFC600;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    @media (max-width: 991px) {
      width: 157px;
      height: 34px;
      font-size: 16px;
      line-height: 24px;
    }
  }
  
  .pricing {
    padding: 40px 10%;
    background: #000000;
  }
  
  .pricing-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 62px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 15px;
    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 1.2;
      margin-bottom: 10px;
    }
  }
  
  .pricing-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }
  
  .pricing-items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
    @media (max-width: 991px) {
      flex-flow: column;
      align-items: center;
      margin-bottom: 14px;
    }
  }
  
  .pricing-item {
    width: 272px;
    height: 225px;
    background: rgba(195, 39, 46, 0.2);
    border: 2px solid #C3272E;
    border-radius: 16px;
    @media (max-width: 991px) {
      width: 314px;
      height: auto;
      border-radius: 10px;
      margin-bottom: 6px;
    }
  }
  
  .pricing-item-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    text-align: center;
    color: #FFFFFF;
    margin: 24px 0 26px 0;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 27px;
      margin: 10px auto;
    }
  }
  
  .pricing-item-amount {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 44px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 24px;
    @media (max-width: 991px) {
      font-size: 22px;
      line-height: 32px;
      margin: 10px auto;
    }
  }
  
  .pricing-item-date {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    color: #FFFFFF;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      margin: 10px auto;
    }
  }
  
  .bottom-btn {
    margin-left: auto;
    margin-right: auto;
  }
  
  .for-questions {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 32px;
    margin-top: 17px;
    & > span, & > a {
      text-decoration-line: underline;
      cursor: pointer;
      color: #FFFFFF;

    }
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  .about-left-img1 {
    width: 258px;
    height: 76px;
    @media (max-width: 991px) {
      width: 150px;
      height: 45.64px;
      margin-left: 10px;
      margin-top: -5px;
    }
  }
  
  .desktop-show {
    display: block;
    @media (max-width: 991px) {
      display: none !important;
    }
  }
  
  .mobile-show {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  
  .about-top {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .whiskey-img {
    @media (max-width: 991px) {
      width: 153px;
      height: 75px;
      margin-bottom: 5px;
      margin-left: 0;
      margin-top: 0;
    }
  }
  
  .whiskey-date {
    @media (max-width: 991px) {
      margin-top: -10px;
    }
  }
 
`;

function Home() {

  const navigate = useNavigate();

  // useToTop();

  return (
    <Container>

      <section className='banner'>
        <div className='banner-text'>
          <div className='banner-title desktop-show'>
            SGHACK IS <br/> OPEN FOR <br/> REGISTRATION
          </div>
          <div className='banner-title mobile-show'>
            SGHACK IS OPEN <br/> FOR REGISTRATION
          </div>
          <div className='banner-date'>8-9 May 2023</div>
          <div className='register-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-2023')}>
            REGISTER NOW
          </div>
        </div>
      </section>

      <section className='about-panel' >
        <div id='about'></div>
        <div className='desktop-show'>
          <div className='about-left-title'>ABOUT</div>
          <img className='about-left-img about-left-img1' src={imgs['80aba99ec20b2bf3fc4d8509fead0ff.png']} />
          <div className='about-left-date'>8-9 May 2023</div>
          <div className='about-left-location'>Singapore</div>
        </div>
        <div className='mobile-show'>
          <div className='about-top'>
            <div className='about-left-title'>ABOUT</div>
            <img className='about-left-img about-left-img1' src={imgs['80aba99ec20b2bf3fc4d8509fead0ff.png']} />
          </div>
          <div className='about-left-date'>8-9 May 2023</div>
          <div className='about-left-location'>Singapore</div>
        </div>
        <div className='about-right desktop-show' style={{flex: '0 0 506px'}}>
          After a pandemic-sized pause, Singapore's premier cyber security training event returns to in-person training! SgHACK, in partnership with WhiskeyCon, brings you unparalleled hands on training for the most discerning cyber security professional.
        </div>
        <div className='about-right mobile-show'>
          After a pandemic-sized pause, Singapore's premier cyber security training event returns to in-person training! SgHACK, in partnership with WhiskeyCon, brings you unparalleled hands on training for the most discerning cyber security professional.
        </div>
      </section>

      <section className='whiskeyCon-panel'>
        <div>
          <img className='about-left-img whiskey-img' src={imgs['Group 2932.svg']} />
          <div className='about-left-date whiskey-date'>9 May 2023</div>
          <div className='about-left-location'>Singapore</div>
        </div>
        <div className='whiskeyCon-right'>
          <div className='whiskeyCon-right-text desktop-show'>
            WhiskeyCon 2023 is an exclusive, one-night only event to be held on <br/>
            Tuesday, 9 May 2023 in Singapore. Security professionals from all over <br/>
            the world will gather for an evening of food and drink to share with, and <br/>
            learn from, each other.
          </div>

          <div className='whiskeyCon-right-text mobile-show'>
            WhiskeyCon 2023 is an exclusive, one-night only event to be held on
            Tuesday, 9 May 2023 in Singapore. Security professionals from all over
            the world will gather for an evening of food and drink to share with, and
            learn from, each other.
          </div>
          <div className='whiskeyCon-right-btn' onClick={() => navigate('/whiskeycon')}>
            LEARN MORE
          </div>
        </div>
      </section>

      <section className='schedule-panel' >
        <div id='schedule'></div>
        <div className='schedule-title'>SGHACK <span>SCHEDULE</span></div>
        <div className='schedule-date'>8-9 May 2023</div>
        <div className='day-panel desktop-show'>
          <span>DAY 1</span>
          <span>DAY 2</span>
        </div>
        <div className='courses desktop-show'>
          <span>Courses</span>
          <span>Courses + WhiskeyCon</span>
        </div>
        <div className='list'>
          <div className='list-item'>
            <img src={imgs['Abhay Bhargav.png']} />
            <div>
              <div className='item-title'>Attacking the Application Supply Chain</div>
              <div className='item-writer'>Instructor: Abhay Bhargav</div>
              <div className='item-btn' onClick={() => navigate('/course1')}>COURSE DETAILS</div>
            </div>
          </div>

          <div className='list-item'>
            <img src={imgs['MalOpSec.png']} />
            <div>
              <div className='item-title'>MalOpSec → EDR: The Great Escape</div>
              <div className='item-writer'>Instructors: Dr. Silvio La Porta and Dr. Antonio Villani</div>
              <div className='item-btn' onClick={() => navigate('/course2')}>COURSE DETAILS</div>
            </div>
          </div>

          <div className='list-item'>
            <img src={imgs['Vishnu.png']} />
            <div>
              <div className='item-title'>DevSecOps Masterclass: AppSec Automation Edition</div>
              <div className='item-writer'>Instructor: Vishnu Prasad</div>
              <div className='item-btn' onClick={() => navigate('/course3')}>COURSE DETAILS</div>
            </div>
          </div>

          <div className='list-item'>
            <img src={imgs['Eric Le Guevel 1.png']} />
            <div>
              <div className='item-title'>Android Security 101</div>
              <div className='item-writer'>Instructor: Eric Le Guevel</div>
              <div className='item-btn' onClick={() => navigate('/course4')}>COURSE DETAILS</div>
            </div>
          </div>

        </div>
      </section>

      <section className='pricing'>
        <div className='pricing-title'>SGHACK PRICING</div>
        <div className='pricing-text'>Below pricing includes the two days of training and evening admission to the WhiskeyCon event.</div>
        <div className='pricing-items'>
          <div className='pricing-item'>
            <div className='pricing-item-title'>EARLY BIRD SPECIAL</div>
            <div className='pricing-item-amount'>SGD$3,200</div>
            <div className='pricing-item-date'>Until 7th April, 2023</div>
          </div>

          <div className='pricing-item'>
            <div className='pricing-item-title'>SPECIAL</div>
            <div className='pricing-item-amount'>SGD$3,300</div>
            <div className='pricing-item-date'>Until 21st April, 2023</div>
          </div>

          <div className='pricing-item'>
            <div className='pricing-item-title'>FINAL SPECIAL</div>
            <div className='pricing-item-amount'>SGD$3,400</div>
            <div className='pricing-item-date'>Until 5th May, 2023</div>
          </div>

          <div className='pricing-item'>
            <div className='pricing-item-title'>STANDARD</div>
            <div className='pricing-item-amount'>SGD$3,500</div>
            <div className='pricing-item-date'>After 6th May, 2023</div>
          </div>
        </div>

        <div className='register-btn bottom-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-2023')}>
          REGISTER NOW
        </div>

        <div className='for-questions'>
          FOR QUESTIONS, EMAIL US AT <a href='mailto:CFT@SGHACK.ORG'>CFT@SGHACK.ORG</a>
        </div>
      </section>

    </Container>
  );
}

export default Home;
