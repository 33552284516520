import useToTop from "../helpers/useToTop";
import Container from "../components/CourseContainer";
import importAllImg from "../helpers/import-all-imgs";

const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));
function Course1() {

  useToTop();

  return (
    <Container>
      <div className='banner'>
        <div className='banner-text'>
          <div className='title desktop-show'>Attacking the Application Supply Chain</div>
          <div className='title mobile-show'>Attacking the <br/> Application Supply Chain</div>
          <div className='date'>8-9 May 2023</div>
          <div className='register-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-2023')}>
            REGISTER NOW
          </div>
        </div>
      </div>

      <div className='main-content'>

        <div className='instructor'>
          <div className='instructor-title'>instructor</div>
          <div className='instructor-content'>
            <img src={imgs['Abhay Bhargav (1).png']} />
            <div>
              <div className='name-title'>
                Abhay Bhargav
                <img src={imgs['icons8-twitter-480 1.svg']} onClick={() => window.open('https://twitter.com/abhaybhargav')}/>
              </div>

              <div className='des'>
                Abhay is the CEO of we45 (www.we45.com) and Founder & Chief Research Officer of AppSecEngineer (www.AppSecEngineer.com). With over 15 years of experience, Abhay is a renowned application security expert and leader in the domain of DevSecOps. Abhay developed the first-ever Threat Modeling solution at the crossroads of Agile and DevSecOps, called ThreatPlaybook, and is the architect of a leading Vulnerability Management and Correlation Product, called Orchestron.
                <div></div>
                Abhay is we45’s lead trainer for courses focused on topics such as DevSecOps, Threat Modeling, Kubernetes Security, Cloud-Native Security and Web Application Security. Abhay is a regular speaker and trainer at OWASP, AppSecUSA, BlackHat, DEFCON, CodeBlue (Japan), JavaOne and other industry events and authored two international publications on Java Security and PCI Compliance.
              </div>
            </div>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>COURSE DESCRIPTION</div>

          <div className='instructor-des'>
            Supply Chain risks are everywhere. We have seen a burst of supply chain exploits targeting organizations which have resulted in billions of dollars of lost value and additional reputational damage. Supply chain security and implementation are both essential to operations and often required by regulation. However, it is important for Penetration Testers and Red Teams to understand how they can leverage supply chain attacks against applications to further strengthen their organization’s defense implementations.
            <div></div>
            This training is a deep hands-on, red team exploration of application supply chains. We will start with an understanding of application supply chains and then dive into story-driven scenarios of exploiting supply-chains, like exploiting CI systems, build systems, container infrastructure and cloud-native infrastructure hosted on Kubernetes, AWS and Azure.
            <div></div>
            People learn better with stories. Our exploit and lateral movement scenarios are intricately designed labs, based upon real world examples, which help facilitate the learning process.
            <div></div>
            Each student gets dedicated environments that they will be deploying and exploiting, giving them a 360-degree view of the attack, and in many cases, the defense as well.
            <div></div>
            We will explore the following supply chain scenarios, exploits and lateral movement examples during this training:

            <ul>
              <li>
                Application Supply Chains
                <div>
                  o Client-side Supply Chain attacks ranging from magecart-style attacks to other client-side exploits
                  <br/>
                  o Server-side dependency attacks
                  <br/>
                  o Build System Attacks and Package Manager focused attacks
                  <br/>
                  o Dependency Confusion Attacks
                  <br/>
                  o Cross-Build Injection Attacks
                </div>
              </li>

              <li>
                Container Supply Chains
                <div>
                  o Container Build System Attacks
                  <br/>
                  o Container Registry Attacks
                  <br/>
                  o Trojanizing Containers
                </div>
              </li>

              <li>
                Attacks against CI Services
                <div>
                  o Attacks against on-prem CI services like Jenkins, Bamboo, etc.
                  <br/>
                  o Webhook Boomerang Attacks against CI/CD Systems
                  <br/>
                  o Dependency attacks and template attacks against Github Actions and Gitlab CI
                </div>
              </li>

              <li>
                Cloud-Native Supply Chain Attacks
                <div>
                  o Attacking Kubernetes Supply-Chains (Helm, Admission Controllers) etc
                  <br/>
                  o Attacking Continuous Deployment Services for Kubernetes and Cloud-native environments
                  <br/>
                  o Supply Chain Attacks and Lateral Movement with AWS and Azure
                </div>
              </li>

            </ul>

            We will also cover more interesting attacks in the form of:

            <ul className='normal-ul'>
              <li>Attacking Kubernetes clusters through malicious Operators</li>
              <li>More Azure Supply-Chain Attacks with Azure DevOps and Attacks against Function Apps and Storage</li>
              <li>More deep-dive attacks against Jenkins and Github Actions</li>
              <li>More Container Supply-Chain Attacks</li>
            </ul>

            Students get access to the labs and training materials for a period of 2 months post training. This includes access to the slides, cloud-labs and dedicated sandbox environments for attack and defense learning.

            <div></div>
            <div className='second-title'>Key Learning Objectives:</div>

            <ul className='normal-ul'>
              <li>Understanding the potential for Supply-Chain Attacks across the Stack. Students will go from Attacking Code Environments, to Build Systems to deployment environments like Cloud and Kubernetes. This provides a very powerful view of supply-chain vulnerabilities through the Stack.</li>
              <li>Understanding the various supply chain elements and risks to those supply-chain elements for any given application.</li>
              <li>A deep-dive story-based red-team perspective with intricate hands-on labs, meant to encourage realistic learning and approaches that they can use from the day after they complete this training, at their job.</li>
            </ul>

            <div className='second-title'>Who Should Attend:</div>

            <ul className='normal-ul'>
              <li>Pentesters</li>
              <li>Red-Teamers</li>
              <li>DevSecOps Professionals</li>
              <li>DevOps Professionals</li>
              <li>Cloud Security Pros</li>
              <li>Application Security Managers</li>
            </ul>

            <div className='second-title'>Pre-requisites</div>

            <ul className='normal-ul'>
              <li>Good knowledge of Application Security Vulnerabilities</li>
              <li>Working knowledge of DevOps, CI and Cloud-Native platforms</li>
              <li>Some familiarity with coding will help</li>
              <li>Some background with offensive security will help</li>
            </ul>

            <div className='second-title'>What students should bring?</div>

            <ul className='normal-ul'>
              <li>Laptop or Tablet computing devices with browser that can connect to the internet with Wi-Fi</li>
              <li>Please ensure that you use devices that are not bound with an extremely strict Web Proxy/DLP</li>
              <li>Github Account. Not a work-related account</li>
              <li>Gitlab Account. Not a work-related Account</li>
            </ul>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>Course Agenda:</div>
          <div className='day-content'>
            <div className='day-title'>
              DAY 1
            </div>
            <div className='day-text'>
              <div className='day-text-title'>Introduction to Application Supply Chain</div>
              <ul>
                <li>Understanding the supply chain landscape</li>
                <li>An overview of supply-chain attack vectors</li>
                <li>MITRE ATT&CK framework for supply-chain compromise</li>
                <li>A brief history of supply-chain attacks</li>
              </ul>

              <div className='day-text-title'>Pre-Build Supply Chain Security</div>
              <ul>
                <li>Understanding the supply chain landscape</li>
                <li>An overview of supply-chain attack vectors</li>
                <li>MITRE ATT&CK framework for supply-chain compromise</li>
                <li>A brief history of supply-chain attacks</li>
              </ul>

              <div className='day-text-title'>Attacking CI Services</div>
              <ul>
                <li>A brief overview of commonly used CI services</li>
                <div className='fit-mobile-margin-left'>
                  o Jenkins <br/>
                  o Bamboo <br/>
                  o GitHub Actions <br/>
                  o GitLab CI <br/>
                </div>

                <li>
                  Attack Stories against CI Systems: We will cover multiple case studies related to attacking CI Services. These attacks look at the approaches where adversaries compromise the CI tools to be able to inject malicious code or otherwise taint organizations’ build process and environments.
                  <div className='fit-mobile-margin-left'>
                    o Build system dependency - Attack vectors <br/>
                    <ul style={{listStyleType: 'disc' }}>
                      <li>Cross build Injection attacks</li>
                    </ul>
                    o CI Service dependency - Attack vectors <br/>
                    o CI based Webhook exploits <br/>
                    o Vulnerabilities and exploits against Jenkins using Jenkins Plugins <br/>
                    o Github Actions exploits using malicious actions and misconfigured Github actions <br/>
                    o Attacking Gitlab using Templating systems and Dependency chaining <br/>
                  </div>
                </li>
              </ul>



            </div>
          </div>

          <div className='day-content'>
            <div className='day-title'>
              DAY 2
            </div>
            <div className='day-text'>
              <div className='day-text-title'>Cloud-Native Supply Chain Attacks</div>
              <ul>
                <li>An overview of cloud and microservices</li>
                <li>
                  A brief intro to Cloud-Native environments
                  <div className='fit-mobile-margin-left'>
                    o AWS <br/>
                    o Azure <br/>
                    o Cloud <br/>
                    o Kubernetes & Microservices
                  </div>
                </li>
                <li>
                  A brief intro to Cloud-Native environments
                  <div className='fit-mobile-margin-left'>
                    o Common attack patterns
                  </div>
                </li>
              </ul>

              <div className='day-text-title'>Attacking Kubernetes Supply Chains</div>
              <ul>
                <li>An overview of Kubernetes and cluster components</li>
                <li>Attack vectors in a Kubernetes cluster</li>
                <li>Leveraging vulnerability registry to upload trojanized image(s)</li>
                <li>Compromising the cluster network</li>
                <li>Helm-Chart based attacks</li>
                <li>Performing Person-In-The-Middle attack to compromise package installations</li>
                <li>Establishing a permanent backdoor to a Kubernetes cluster through malicious packages and CRDs</li>
                <li>Leveraging Kubernetes Webhooks to perform Cluster Privilege Escalation Attacks</li>
              </ul>

              <div className='day-text-title'>Compromising AWS Environments</div>
              <ul>
                <li>Overview of AWS components</li>
                <li>
                  Introduction to AWS Lambda
                   <div style={{marginLeft: 20}}>o Understanding layers</div>
                </li>
                <li>Compromising Lambda with excessive privileges</li>
                <li>Performing lateral movement to gain access to s3 and manipulating sensitive objects</li>
                <li>Compromising cloud environments through malicious executables</li>
                <li>Injecting malicious scripts in s3 CDN to mine crypto – for fun and profit</li>
                <li>Attacking ECR registries with faulty IAM privileges</li>
              </ul>

              <div className='day-text-title'>Compromising Azure Environments with Supply Chain Attacks</div>
              <ul>
                <li>Understanding the Azure Services and IAM Model</li>
                <li>Attacking Azure Function Apps to compromise underlying container infrastructure and escalating privileges into the Azure Account</li>
                <li>Attacking Azure DevOps implementations for Account Compromise Scenarios</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Course1;
