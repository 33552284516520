import styled from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import { useNavigate } from "react-router-dom";

const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));
const Container = styled.footer`
  padding: 70px 15% 40px 15%;
  background: #C3272E;

  @media (max-width: 991px) {
    padding: 20px 10%;
  }
  
  .follow-us {
    @media (max-width: 991px) {
      margin-top: 10px;
    }
  }

  .footer-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;
    @media (max-width: 991px) {
      flex-flow: column;
    }
  }

  .about-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFF;
    margin-top: 18px;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
  }

  .contact-us-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    color: #FFFFFF;
    margin-bottom: 24px;
    @media (max-width: 991px) {
      margin-top: 0px;
      margin-bottom: 5px;
    }
  }

  .email-panel {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFF;
    cursor: pointer;
    & > a {
      color: #FFF;
      text-decoration: underline;
    }
  }

  .follow-items {
    display: flex;
    //display: none;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    column-gap: 20px;
    @media (max-width: 991px) {
      justify-content: flex-start;
      margin-bottom: 10px;
    }
    & > img {
      cursor: pointer;
      @media (max-width: 991px) {
        height: 24px;
      }
    }
  }

  .copy-right {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFF;
    padding-top: 15px;
    border-top: 1px solid #9B9EAC;
  }

  .desktop-show {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .mobile-show {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
`;

function Footer() {
  const navigate = useNavigate();
  return (
    <Container>
      <div className='footer-top'>
        <div className='about-us'>
          <img src={imgs['Group (9).svg']} />
          <div className='about-text desktop-show'>
            SgHACK, in partnership with WhiskeyCon, <br/>
            brings you unparralled hands on training <br/>
            for the most discerning cyber security <br/>
            professional.
          </div>
          <div className='about-text mobile-show'>
            SgHACK, in partnership with WhiskeyCon,
            brings you unparralled hands on training
            for the most discerning cyber security
            professional.
          </div>
        </div>

        <div>
          <div className='contact-us-title'>Contact Us</div>
          <div className='email-panel'>
            Email: <a href='mailto:contact@SgHACK.org'>contact@SgHACK.org</a>
          </div>
        </div>

        <div className='follow-us' >
          <div className='contact-us-title'>Follow Us</div>
          <div className='follow-items'>
            <img onClick={() => window.open('https://x.com/hashtag/SgHACK?src=hashtag_click')} src={imgs['Vector (7).svg']} />
            <img onClick={() => window.open('https://signal.me/#eu/zSTIpaVLVr_i767UQgBe3RxcbL0l6l8TllHR3xhWSbpxuMUJvcdOG472HQlpnTlE')} src={imgs['signal-svgrepo-com.svg']} />
            {/*<img src={imgs['instagram.svg']} />*/}
          </div>
          <div className='email-panel' onClick={() => navigate('/terms')}>
            Privacy Policy
          </div>
        </div>
      </div>

      <div className='copy-right'>
        Copyright © 2024 All rights reserved
      </div>
    </Container>
  )
}

export default Footer;
