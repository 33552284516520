import importAllImg from "../helpers/import-all-imgs";
import useToTop from "../helpers/useToTop";
import Container from "../components/CourseContainer";

const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));


function Course3() {

  useToTop();
  return (
    <Container>
      <div className='banner'>
        <div className='banner-text'>
          <div className='title desktop-show'>DevSecOps Masterclass: AppSec <br/> Automation Edition</div>
          <div className='title mobile-show'>DevSecOps Masterclass: <br/> AppSec  Automation Edition</div>
          <div className='date'>8-9 May 2023</div>
          <div className='register-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-2023')}>
            REGISTER NOW
          </div>
        </div>
      </div>

      <div className='main-content'>

        <div className='instructor'>
          <div className='instructor-title'>instructor</div>
          <div className='instructor-content'>
            <img src={imgs['Vishnu (1).png']} />
            <div>
              <div className='name-title'>
                Vishnu Prasad
                <img src={imgs['icons8-twitter-480 1.svg']} onClick={() => window.open(' https://twitter.com/abhaybhargav')}/>
              </div>

              <div className='des'>
                As the DevSecOps Lead at we45, Vishnu is a DevSecOps and Security Automation wizard. Vishnu has implemented security in DevOps for numerous Fortune 500 companies and has extensive experience in Continuous Integration and Continuous Delivery across various verticals, using DevOps tools like Jenkins, Selenium, Docker, amongst others. Vishnu is an expert in automating SAST, DAST, and SCA security tools at every phase of the build pipeline and commands knowledge of every major security tool out, including ZAP, Burp, Findsecbugs, and npm audit. As a tireless innovator, Vishnu Dockerized his entire security automation process for cross-platform support to build pipelines seamlessly.
                <div></div>
                With experience extending well-beyond DevSecOps, Vishnu designs and develops Web Application Security tools, performs vulnerability management and orchestration, and consults on security assessments for major companies. With proficiency in languages like Python, Java, Javascript, Angular, and more, Vishnu regularly trains major companies and team members on topics such as application security automation, DevSecOps, and AppSec Essentials.
              </div>
            </div>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>COURSE DESCRIPTION</div>

          <div className='instructor-des'>
            This training takes a comprehensive, focused, and practical approach at implementing DevSecOps practices with a focus on Application Security Automation. The training is a glued-to-your-keyboard, hands-on journey with labs that are backed by practical examples of DevSecOps and AppSec Automation.
            <div></div>
            The Training starts with a view of DevSecOps, specifically in terms of embedding security activities in multiple stages of the Software Development Lifecycle. Subsequently, the training delves into specific Application Security Automation approaches for SAST, SCA and Supply-Chain Security, DAST and Integration of these tools into CI/CD tools and Automation Pipelines.
            <div></div>
            In this edition, we are completely rebuilding our existing DevSecOps content to reflect the very bleeding edge of Application Security Automation and DevSecOps Approaches. Including, but not limited to:
            <ul className='normal-ul'>

              <li>Hands-on SAST for Apps and Infrastructure-as-Code, with a focus on Semgrep and CodeQL. Develop Custom SAST rules like a bawse!</li>
              <li>Supply-Chain Security Automation: SBOMs, Source Composition Analysis and Security Engineering techniques. This segment will additionally have several approaches to building secure base images for containers.</li>
              <li>Supply-Chain Assurance and Provenance for artifacts. Supply-Chain Security attacks are largely caused by lack of assurance and poor provenance of software supply-chain artifacts. We will be diving into the SLSA (Supply-Chain Levels for Software Artifacts) Standard and how automation can help achieve levels of compliance. In addition, we willl be diving into Cosign from Project sigstore. This can be used to generate keyed/keyless signatures for container images and other build artifacts including packages and SBOMs.</li>
              <li>Secret Management. This segment of the class will dive into Secrets Management and encryption tools like Hashicorp Vault. We will discuss advanced implementations examples for Encryption, Key Management and Dynamic Secrets.</li>
              <li>DAST Automation with OWASP ZAP and Nuclei. We willl be exploring API based scanning with OWASP ZAP and Test Automation Frameworks. In addition, we will explore using and building custom DAST automation with Nuclei. This will both aid in integrating DAST into Automation Pipelines and be used for Security Regressions for more complex vulnerabilities.</li>
              <li>Policy-As-Code with Open Policy-Agent (OPA). OPA is a powerful framework that can be used to create and enforce policies across a variety of deployment environments. From being used to perform Access Control and Input Validation in API Gateways, to being used in Container Registries and Operating Systems for deploying and enforcing security policies, you will learn OPA’s Domain Specific Language, rego in order to understand policy-as-code frameworks.</li>
              <li>Integrating Security Automation with CI/CD tooling.We will explore integrating Security Automation with CI/CD tools including Github Actions, Gitlab and Jenkins. Additionally, we will leverage Data Flow Automation tools like Robot Framework, Gaia and Prefect to provide alternatives to typical CI/CD tools for AppSec Automation.</li>

            </ul>

            Students get access to the labs and training materials for a period of 2 months post training. This includes access to the slides, cloud-labs and dedicated sandbox environments for attack and defense learning.
            <div></div>

            <div className='second-title'>Key Learning Objectives:</div>

            <ul className='normal-ul'>
              <li>Be able to apply battle-tested Application Security Automation Techniques + Practical Security Pipelines with both conventional and unconventional techniques.</li>
              <li>Be able to apply the concepts learned, which are presented from real-world implementations and practiced during hands-on lab training and use the tools and knowledge immediately after completing the course.</li>
              <li>Gain an in-depth perspective of security automation on multiple tech stacks and environments through cookbook-style security automation coverage of DAST, SAST and SCA.</li>
              <li>Understand the critical importance of comprehensive Secrets Management and CI / CD coverage, including Automation Frameworks, pertaining to the implementation of DevSecOps in an organization’s development environment.</li>
            </ul>


            <div className='second-title'>Who Should Attend:</div>

            Anyone with an interest in protecting the Application Supply Chain, such as Penetration Testers, Red Teamers, DevSecOps and DevOps Professionals, Cloud Security Professionals and Application Security Managers.

            <div></div>
            <div className='second-title'>Pre-requisites</div>

            <ul className='normal-ul'>
              <li>
              Working knowledge of Application Security concepts and vulnerabilities (OWASP Top 10, Application Security concepts)
              </li>
              <li>
                Basic knowledge of Linux command line
              </li>
              <li>
                Basic knowledge of some (any) programming language
              </li>
            </ul>

            <div className='second-title'>What students should bring?</div>

            <ul className='normal-ul'>
              <li>A laptop with at least 8GB of RAM (16GB recommended)</li>
              <li>Github Account. Not a work-related Account</li>
              <li>Gitlab Account. Not a work-related Account</li>
            </ul>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>Course Agenda:</div>
          <div className='day-content'>
            <div className='day-title'>
              DAY 1
            </div>
            <div className='day-text'>

              <div className='day-text-title'>The Problem with the old models of Application Delivery</div>
              <ul className='normal-ul'>
                <li>A Quick History of Agile and DevOps</li>
                <li>The Coming of DevOps</li>
                <li>The Need for Security in DevOps</li>
                <li>Security in Continuous Integration and Continuous Deployment</li>
              </ul>


              <div className='day-text-title'>Introduction to Static Application Security Testing (SAST) for Continuous Integration</div>

              <ul>
                <li>
                  Static Analysis Type
                  <div>
                    o RegEx Tools <br/>
                    o Abstract Syntax Trees <br/>
                    o QL/Semantic Grep Tools => CodeQL and Semgrep
                  </div>
                </li>
                <li>
                  Semgrep Deep-Dive
                  <div>
                    o Rules Syntax <br/>
                    o Taint Analysis <br/>
                    o Metavariables, Metafunctions and MetaClasses <br/>
                    o Semgrep against multiple languages: Python, JavaScript, Go(lang), Java and Ruby
                  </div>
                </li>

                <li>
                  CodeQL Deep-Dive
                  <div>
                    o Rules Syntax <br/>
                    o CodeQL VSCode Composition Tools <br/>
                    o Semgrep against multiple languages:	C#, Python,	Java and	JavaScript
                  </div>
                </li>

                <li>Challenge Segment - Finding Bugs with Semgrep and CodeQL</li>
                <li>
                  Static Analysis Automation Strategies Hands-on
                  <div>
                    o Automation in IDE <br/>
                    o Automation - Part of Git hooks <br/>
                    o Automation - PR and MR Static Analysis Tooling (Github Actions, etc) <br/>
                    o Automation - Build Pipeline and Pre-Deployment
                  </div>
                </li>

                <li>
                  Static Analysis for Infrastructure-as-Code Hands-on
                  <div>
                    o Kube-Linter <br/>
                    o Checkov <br/>
                    o Integrating Infrastructure-as-Code Scanning with Github Actions and Deploy pipelines
                  </div>
                </li>

                <li>
                  Static Analysis in CI and CD Pipelines Hands-on
                  <div>
                    o Github Actions <br/>
                    o	Gitlab Dev <br/>
                    o	Jenkins
                  </div>
                </li>

              </ul>

              <div className='day-text-title'>
                Source Composition Analysis and Software Bill of Materials in DevSecOps
              </div>
              <ul>
                <li>
                  Concept Overview
                  <div className='sub-list'>
                    o Artifact Lifecycle <br/>
                    o SBOM <br/>
                    o Package Provenance <br/>
                    o SLSA - Supply-Chain Levels for Software Artifacts <br/>
                    o Source Composition Analysis
                  </div>
                </li>

                <li>
                  Package Provenance and Assurance Deep-Dive
                  <div className='sub-list'>
                    o Cosign Deep-Dive - Keyed and Keyless <br/>
                    o SLSA Provenance Generator for Github Actions and Levels
                  </div>
                </li>

                <li>
                  SBOM Deep-dive & Hands-on
                  <div className='sub-list'>
                    o CycloneDX <br/>
                    o SPDX, SWID
                  </div>
                </li>

                <li>
                  SCA Deep-dive and Automation Strategies Hands-on
                  <div className='sub-list'>
                    o Incremental SCA with Github Actions => Pull Requests and Merge Requests <br/>
                    o Package Manager integrated SCA with NPM, Poetry, Dependabot <br/>
                    o OWASP Dependency Track and Dependency Check
                  </div>
                </li>
              </ul>



            </div>
          </div>

          <div className='day-content'>
            <div className='day-title'>
              DAY 2
            </div>
            <div className='day-text'>

              <div className='day-text-title'>Dynamic Application Security Testing (DAST) with Continuous Integration</div>
              <ul>
                <li>
                  Concepts of DAST with Security Testing
                  <div className='sub-list'>
                    o Security Automation Testing using OWASP ZAP, Selenium, OpenAPI (Swagger) <br/>
                    o Security Regression Tests - How to design and write them
                  </div>
                </li>

                <li>
                  Nuclei Deep-Dive & Hands-on
                  <div className='sub-list'>
                    o Nuclei Templates <br/>
                    o Integrating Nuclei into Pipelines <br/>
                    o Using Nuclei for Security Regression
                  </div>
                </li>

                <li>
                  Application Security Automation and Test Orchestration Deep-Dive & Hands-on
                  <div className='sub-list'>
                    o Nuclei Templates
                    <div className='sub-list'>
                      ▪ Scan Policy <br/>
                      ▪ Extensions
                    </div>
                  </div>

                  <div className='sub-list'>
                    o OWASP ZAP API Deep-Dive
                    <div className='sub-list'>
                      ▪ Leveraging OWASP ZAP API with Selenium for testing browser-based applications <br/>
                      ▪ Leveraging OWASP ZAP API and (Tavern/RESTInstance/Chai) to test web services and microservices <br/>
                      ▪ OWASP ZAP API Testing with OpenAPI Specification
                    </div>
                  </div>

                  <div className='sub-list'>
                    o OWASP ZAP Scripting Workshop
                    <div className='sub-list'>
                      ▪ Create Active Scan Scripts for Custom Application Vulnerabilities
                    </div>
                  </div>
                </li>


                <li>
                  Policy-as-code with Open Policy Agent
                  <div className='sub-list'>
                    o Open Policy Agent Basics and Framework Overview <br/>
                    o Hands-on: Rego Basics - Language essentials and composition rules
                    <div className='sub-list'>
                      ▪ Using OPA and Rego for API RBAC and AuthZ Implementation with API Gateways <br/>
                      ▪ Using OPA for Advanced Input Validation for APIs <br/>
                      ▪ Using OPA for Terraform Policy Definition and Enforcement
                    </div>
                  </div>
                </li>

                <li>
                  Secrets Management
                  <div className='sub-list'>
                    o Intro to Secrets Management - A Case for a structured approach to managing secrets <br/>
                    o Secrets vs Sensitive Information - A Distinction and varied Threat Model <br/>
                    o Secret Management Fails
                    <div className='sub-list'>
                      ▪ Secret Management in GitOps fails <br/>
                      ▪ Real-world incidents that were caused extensively by bad secrets management
                    </div>
                  </div>
                </li>

                <li>
                  Secrets Management with Hashicorp Vault, Hands-on
                  <div className='sub-list'>
                    o Introduction to HashiCorp Vault and its API <br/>
                    o Deploying Vault in Prod <br/>
                    o Managing Secrets with Vault => Static Secrets <br/>
                    o Encryption, Key Rotation and Rewrapping with Vault Transit Secrets Engine <br/>
                    o Dynamic Secrets with Vault => Using Dynamic Secrets for short-term leases for databases
                  </div>
                </li>

                <li>
                  Pipelines and Tooling
                  <div className='sub-list'>
                    o Overview of Tooling
                    <div className='sub-list'>
                      ▪ Github Action <br/>
                      ▪ Gitlab <br/>
                      ▪ Jenkins <br/>
                      ▪ Data Flow Automation Tools: Prefect, Gaia, Apache Airflow
                    </div>
                    o Hands-on
                    <div className='sub-list'>
                      ▪ DevSecOps Pipelines with Github Actions <br/>
                      ▪ DevSecOps Pipelines with Gitlab <br/>
                      ▪ DevSecOps with Jenkins <br/>
                      ▪ DevSecOps with Gaia and Prefect
                    </div>
                  </div>
                </li>



              </ul>

            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Course3;
