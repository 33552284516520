import styled from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";


const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));
const Container = styled.div`
  .banner {
    background-image: url("${imgs['Group 2930.png']}");
    background-size: cover;
    background-repeat: no-repeat;
    height: 340px;
    background-color: rgba(0, 0, 0, 0.4);
    margin-top: 100px;
    @media (max-width: 991px) {
      background-image: url("${imgs['Group 2935.png']}");
      height: 270px;
      margin-top: 70px;
    }
  }
  
  .banner-text {
    padding-top: 96px;
    @media (max-width: 991px) {
      padding-top: 36px;
    }
  }
  
  .title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 62px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 17px;
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 40px;
      margin-bottom: 15px;
    }
  }
  
  .date {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 47px;
    text-align: center;
    color: #FFC600;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 15px;
    }
  }

  .register-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    width: 222px;
    height: 51px;
    background: #C3272E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 991px) {
      width: 170px;
      height: 39px;
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  .main-content {
    padding: 22px 10%;
    height: auto;
    background-color: black;
    background-image: url("${imgs['Group 2931.png']}");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -10%;
    @media (max-width: 991px) {
      background-image: none;
    }
  }
  
  .instructor {
    background: #F2F2F7;
    border-radius: 25px;
    padding: 50px 60px;
    margin-bottom: 25px;
    @media (max-width: 991px) {
      padding: 20px 10%;
    }
  }
  
  .instructor-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 10px;
      text-align: center;
    }
  }
  
  .instructor-content {
    display: flex;
    align-items: flex-start;
    & > img {
      margin-right: 50px;
      @media (max-width: 991px) {
        margin-right: 0;
        width: 184px;
        height: 184px;
      }
    }
    @media (max-width: 991px) {
      flex-flow: column;
      align-items: center;
    }
  }
  
  .name-title {
    display: flex;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
    align-items: center;
    margin-bottom: 15px;
    & > img {
      margin-left: 10px;
      cursor: pointer;
      @media (max-width: 991px) {
        margin-right: 0;
        width: 30px;
        height: 30px;
      }
    }
    @media (max-width: 991px) {
      flex-flow: column;
      font-size: 16px;
      line-height: 40px;
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }
  
  .des {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    & > div {
      margin-bottom: 20px;
    }
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 15px;
    }
  }
  
  .instructor-des {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 15px;
    }
    
    & > div {
      margin-bottom: 30px;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
    
    li {
      margin-bottom: 20px;
    }
    
    .normal-ul {
      & > li {
        margin-bottom: 0;
      }
    }
    
    .second-title {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #000000;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  
  .day-content {
    display: flex;
    margin-bottom: 36px;
    @media (max-width: 991px) {
      flex-flow: column;
      margin-bottom: 28px;
    }
  }
  
  .day-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 62px;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: 0 0 140px;
    background: #C3272E;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    @media (max-width: 991px) {
      flex: 0 0 30px;
      margin-right: 0;
      margin-bottom: 10px;
      font-size: 20px;
      line-height: 1.2;
      align-items: center;

    }
  }
  
  .day-text-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 11px;
      line-height: 17px;
    }
  }
  
  .day-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 11px;
      line-height: 17px;
    }
    
    ul {
      margin-top: 5px;
      @media (max-width: 991px) {
        padding-left: 20px;
      }
    }
    
    .sub-list {
      margin-left: 20px;
    }
  }

  .desktop-show {
    display: block;
    @media (max-width: 991px) {
      display: none !important;
    }
  }

  .mobile-show {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
`;

export default Container;
