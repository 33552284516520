import useToTop from "../helpers/useToTop";
import styled from 'styled-components';

const Container = styled.div`
  padding: 45px 10%;
  background: #fff;
  margin-top: 100px;
  @media (max-width: 991px) {
    margin-top: 70px;
  }
  .title {
    //font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;

    //font-family: 'Titillium Web';
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;

    color: #000000;

    @media (max-width: 991px) {
      font-size: 24px;
    }


  }
  
  .text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;

    color: #000;

    @media (max-width: 991px) {
      font-size: 11px;
      line-height: 15px;
    }
    
    & > div {
      margin-bottom: 20px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
  }
  
  .sub-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin: 20px auto;
    @media (max-width: 991px) {
      font-size: 11px;
      line-height: 15px;
    }


    &.special {
      text-decoration: underline;
      margin: 40px 0 20px 0;

      @media (max-width: 991px) {
        margin: 20px 0 10px 0;
      }
    }
  }
`

function Terms() {
  useToTop();

  return (
    <Container>
      <div className='title'>Privacy Policy</div>
      <div className='text'>
        The SGHack recognizes the importance of protecting your personal and financial information when you visit our website located at https://sghack.org/ (“Website“). The following information is designed to help you understand the information collection practices at this Website.
        <div></div>
        By visiting this Website, you are accepting the practices described in this Privacy Policy. If you do not agree to this policy, please do not use this Website. Please review the terms and conditions on the Website to learn of other terms and conditions applicable to your use of this Website.

        <div className='sub-title special'>Information Collection and Use</div>

        <div className='sub-title'>Methods of Information Collection</div>

        We may receive personally identifiable information you submit to or through our Website. By disclosing your personally identifiable information to us via our Website, you consent to our collection, use and disclosure of your personally identifiable information for the purposes as set out in this Privacy Policy.  Failure to provide such personally identifiable information may result in the SGHack being unable to provide you with the services or information requested.

        <div className='sub-title'>Information Collected and Purpose of Information Collection</div>
        The types of personally identifiable information that may be collected at our Website include information you provided to us through the “Contact Us” page (which may include your name and email address).

        <div className='sub-title'>Use of Personally Identifiable Information</div>

        The personally identifiable information you submit to or through our Website is used only for the following purposes:
        <ul>
          <li>Responding to your inquiry sent to us; and</li>
          <li>Other directly related purposes.</li>
        </ul>

        We will not sell, share, rent or otherwise disclose or transfer your personally identifiable information to others without your consent.
        <div className='sub-title'>Retention Period</div>
      </div>
    </Container>
  )
}

export default Terms;
