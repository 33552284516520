import importAllImg from "../helpers/import-all-imgs";
import useToTop from "../helpers/useToTop";
import Container from "../components/CourseContainer";

const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));

function Course2() {
  useToTop();

  return (
    <Container>
      <div className='banner'>
        <div className='banner-text'>
          <div className='title desktop-show'>MalOpSec → EDR: The Great Escape</div>
          <div className='title mobile-show'>MalOpSec → EDR: The <br/> Great Escape</div>
          <div className='date'>8-9 May 2023</div>
          <div className='register-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-2023')}>
            REGISTER NOW
          </div>
        </div>
      </div>

      <div className='main-content'>

        <div className='instructor'>
          <div className='instructor-title'>instructor</div>
          <div className='instructor-content'>
            <img src={imgs['MalOpSec (1).png']} />
            <div>
              <div className='name-title'>
                Dr. Silvio La Porta
                <img src={imgs['icons8-twitter-480 1.svg']} onClick={() => window.open('https://twitter.com/DrCh40s')}/>
              </div>

              <div className='des'>
                Dr. Silvio La Porta is CEO and Co-Founder at RETooling (retooling.io) where he defines and develops a threat actor emulation platform which enables Red Teams to recreate realist attack scenarios. Silvio previously was a senior cyber security architect designing security products and researching advanced detection technology for complex malware / advanced persistent threats (APT).
                <div></div>
                Silvio served as a lead research scientist with EMC’s European research division based in the Centre of Excellence in Cork, Ireland where his primary focus areas were real-time network monitoring and data analysis in smart grids to detect malware activity in SCADA systems and corporate networks.
                <div></div>
                Silvio also led security service level agreement (SEC-SLA) and End User security/privacy protected data store projects for hybrid cloud environments. Before joining EMC, Silvio worked as a malware reverse engineer in Symantec’s security response team in Dublin, Ireland.
                <div></div>
                Silvio holds a PhD in computer network security from the University of Pisa, Italy and is a frequent speaker in various professional and industry conferences.
              </div>

              <div className='name-title' style={{marginTop: 15}}>
                Dr. Antonio Villani
                <img src={imgs['icons8-twitter-480 1.svg']} onClick={() => window.open('https://twitter.com/t0nvi')}/>
              </div>

              <div className='des'>
                Dr. Antonio Villani is Co-Founder at RETooling (retooling.io) and is responsible for analyzing high-level implants for top-tier customers and providing detailed implementation information to support cyber-defense and cyber-threat intelligence teams. Antonio specializes in reverse-engineering multi-stage implants in order to improve attack emulation products.
                <div></div>
                Antonio is an accomplished researcher and has been published in many top-tier journals and conferences. Antonio participates in European research projects focusing on cyber resilience and data security and, during his PhD studies, previously worked in the field of malware research and digital forensics.
              </div>
            </div>

          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>COURSE DESCRIPTION</div>

          <div className='instructor-des'>
            This course will present an in-depth description of the techniques implemented in modern malware to evade mainly EDR and their internal AV systems and cover real-world scenarios that impair (effectively slow-down or dissuade) reverse engineering efforts and make the job of first responders tougher.
            <div></div>
            Techniques will be demonstrated in two ways: first, by reversing real malware samples, and then by re-implementing an improved version of the malware code.
            <div></div>
            This training is designed from an attacker's point of view, teaching Red Teams how to make their implants stealthier, but it will also teach defenders how to deal with the anti-reversing and the OpSec techniques demonstrated in class.
            <div></div>
            The course focuses on Windows malware and on the analysis, tweaking, and re-purposing of real malware samples. Participants will be provided with plenty of custom code to facilitate the understanding of complex malware techniques.
            <div></div>
            As part of the course, theory sessions will be followed by exercises where participants will reverse and re-implement specific parts of real malware to fully understand the hidden corners of all the techniques involved.
            <div></div>
            Fifty percent (50%) of the course will be dedicated to hands-on labs that will show how to translate the theory principles into practice. These labs are designed to provide flexibility in terms of complexity and include bonus tracks to ensure that you always feel engaged and have something interesting to explore and learn.

            <div></div>
            <div className='second-title'>Key Learning Objectives:</div>

            <ul className='normal-ul'>
              <li>Be able to recognize, implement and deal with stealthy malware/backdoors evasion techniques and tradecrafts.</li>
              <li>Be able to modify malware components to protect them against reversing efforts.</li>
              <li>Familiarize with the .NET advanced obfuscation system.</li>
              <li>Be able to build custom obfuscators and to recognize some pattern left by some obfuscation transforms.</li>
              <li>Learn tradecrafts used by attackers to prevent and effectively impair defensive incident responders from analyzing their tools, payloads, and backdoors.</li>
            </ul>

            <div className='second-title'> Who Should Attend:</div>

            Developers and Reverse Engineers who want to understand the tradecraft from a different point of view, Red Team members who want to go beyond using third-party implants, and Researchers who want to develop anti-detection techniques of real malware/apt.
            <div></div>
            <div className='second-title'>Pre-requisites</div>
            <ul className='normal-ul'>
              <li>Programming experience (C, C++, Python, .NET, and PowerShell)</li>
              <li>Be familiar with assembly language and Debuggers (IDA pro, WinDBG)</li>
            </ul>

            <div className='second-title'>What students should bring?</div>
            <ul className='normal-ul'>
              <li>
                Laptop Requirements:
                <div>
                  o  Virtualization capable CPU(s)
                  o  Minimum 8GB of RAM (for running one guest VM)
                  o  Minimum 80 GB free disk space
                </div>
              </li>
              <li>
                Software Requirements:
                <div>
                  o  Host OS Windows 10 64-bit
                  o  Debugging Tools for Windows (Ida Pro, WinDBG). Decompiler recommended.
                  o  SysInternals Tools
                  o  Virtualization Software (VMWare, VirtualBox)
                  o  Guest OS Windows 10 64-bit Version 20H2
                  o  System Administrator access required on both host and guest Oss
                </div>
              </li>
            </ul>






          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>Course Agenda:</div>
          <div className='day-content'>
            <div className='day-title'>
              DAY 1
            </div>
            <div className='day-text'>
              <ul>
                <li>The shortest Intro</li>
                <li>How they Work</li>
                <li>
                  All the way your stuff gets detected and the magic of Memory (EDR anatomy)
                  <div className='sub-list'>
                    o EDR architecture <br/>
                    o Kernel driver, ELAM <br/>
                    o Inline hooks <br/>
                    o Static, Heuritics and Behavioural
                  </div>
                </li>
                <li>File Scanners</li>
                <li>
                  Memory Scanners
                  <div className='sub-list'>
                    o Moneta <br/>
                    o PE-sieve <br/>
                    o Other memory scanner tools
                  </div>
                </li>

                <li>How to evade them</li>
                <li>Smashing file signature - Change the static features to stay under the radar</li>
                <li>
                  Unhooking the watchers in all the possible ways
                  <div className='sub-list'>
                    o Restore the original ntdll <br/>
                    o Patch the hooked ntdll in memory <br/>
                    o The right ways of using call gates
                  </div>
                </li>

                <li>Self-protecting implant memory code</li>


              </ul>




            </div>
          </div>

          <div className='day-content'>
            <div className='day-title'>
              DAY 2
            </div>
            <div className='day-text'>
              <div>
                · Mastering ETW and get the forbidden feed <br/>
                · Using ROP to do good or better bad things <br/>
                · Smashing the stack for fun and evasions <br/>
                · Obfuscate and make hard to reverse your C# stage0
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Course2;
