import {useEffect} from "react";

function useToTop() {
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);
}

export default useToTop;
