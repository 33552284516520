import styled from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import {useNavigate} from "react-router-dom";
import useToTop from "../helpers/useToTop";
const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));

const Container = styled.div`
  
  .banner {
    background-image: url("${imgs['Group 2936.png']}");
    background-size: cover;
    background-repeat: no-repeat;
    height: 610px;
    margin-top: 100px;
    @media (max-width: 991px) {
      background-image: url("${imgs['Group 2937.png']}");
      height: 270px;
      margin-top: 70px;
    }
  }

  .banner-text {
    padding-top: 240px;
    margin-left: 15%;
    @media (max-width: 991px) {
      padding-top: 80px;
      margin-left: 10%;
    }
  }

  .banner-title {
    font-weight: 400;
    font-size: 64px;
    line-height: 62px;
    color: #FFFFFF;
    margin-bottom: 50px;
    font-family: 'Fugaz One';
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 5px;
    }
  }

  .banner-date {
    font-weight: 400;
    font-size: 32px;
    line-height: 47px;
    color: #FFC600;
    margin-bottom: 10px;
    font-family: 'Fugaz One';
    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 5px;
      width: 60%;
    }
  }

  .register-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    width: 222px;
    height: 51px;
    background: #C3272E;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    @media (max-width: 991px) {
      width: 170px;
      height: 39px;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .about-panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 75px 10% 75px 15%;
    @media (max-width: 991px) {
      flex-flow: column;
      padding: 15px 10%;
    }
  }

  .about-right {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    & > div {
      margin-bottom: 15px;
    }

    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      text-align: center;;
      & > br {
        display: none;
      }
    }


  }

  .about-left-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    color: #000000;
    margin-bottom: 5px;
  }

  .about-left-date {
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #FFC600;
    margin: 14px 0 5px;
    font-family: 'Fugaz One';
    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 29px;
      text-align: center;
      margin-top: -10px;
    }
  }

  .about-left-location {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #909090;
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 15px;
      text-align: center;
    }
  }

  .about-left-img {
    margin-left: -10px;
    @media (max-width: 991px) {
      width: 153px;
      height: 73.27px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .schedule-panel {
    padding: 0px 10% 40px 15%;
    background: #000000;
    @media (max-width: 991px) {
      padding: 10px 10% 30px 10%;
    }
  }

  .schedule-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;
    & > span {
      color: #C3272E;
    }

    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 1.2;
      margin-bottom: 30px;
    }
  }
  
  .call-item {
    margin-bottom: 40px;

    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
  
  .call-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #000000;
    margin-bottom: 10px;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 26px;
    }
  }
  
  .call-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    & > a {
      text-decoration-line: underline;
      color: #C3272E;
      cursor: pointer;
    }
    & > div {
      margin-bottom: 10px;
    }

    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  .call-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 13px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }
    & > div {
      display: flex;
      align-items: center;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
      & > img {
        margin-right: 18px;
        @media (max-width: 991px) {
          width: 20px;
          height: 20px;
          margin-right: 11px;
        }
      }
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
  
  .speakers {
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-flow: column;
    }
  }
  
  .speaker-item {
    //height: 200px;
    flex: 0 0 45%;
    background: rgba(195, 39, 46, 0.1);
    border: 2px solid #C3272E;
    border-radius: 12px;
    padding: 22px 19px;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
  }
  
  .speaker-item-top {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    height: 54px;
    margin-bottom: 20px;
    & > span {
      font-weight: 700;
    }

    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
      height: auto;
    }
  }
  
  .speaker-des-item {
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    & > img {
      margin-right: 18px;
      @media (max-width: 991px) {
        width: 20px;
        height: 20px;
        margin-right: 16px;
      }
    }
    @media (max-width: 991px) {
      width: 100%;
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 10px;
    }
  }
  
  .section {
    margin-bottom: 30px;
    display: flex;
    & > div:nth-child(1) {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 33px;
      color: #000000;
      flex: 0 0 178px;
      margin-right: 44px;
      @media (max-width: 991px) {
        font-size: 16px;
        line-height: 24px;
        flex: 0 0 130px;
      }
    }
    
    & > div:nth-child(2) {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 21px;
      }
    } 
  }
  
  .important-text {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin-bottom: 20px;
    & > span {
      display: inline-block;
      color: #C3272E;
      width: 150px;
      @media (max-width: 991px) {
        width: 120px;
      }
    }
    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
    }
  }
  
  .bottom-btn {
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 991px) {
      margin: 25px auto;
    }
  }
  
  .here {
    color: #C3272E;
  }

  .instructor {
    background: #F2F2F7;
    border-radius: 25px;
    padding: 50px 60px;
    margin-bottom: 25px;
    @media (max-width: 991px) {
      padding: 20px 10%;
    }
  }

  .instructor-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 1.2;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .instructor-content {
    display: flex;
    align-items: flex-start;
    & > img {
      margin-right: 50px;
      @media (max-width: 991px) {
        margin-right: 0;
        width: 184px;
        height: 184px;
      }
    }
    @media (max-width: 991px) {
      flex-flow: column;
      align-items: center;
    }
  }

  .name-title {
    display: flex;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
    align-items: center;
    margin-bottom: 15px;
    & > img {
      margin-left: 10px;
      cursor: pointer;
      @media (max-width: 991px) {
        margin-right: 0;
        width: 30px;
        height: 30px;
        &:nth-of-type(2) {
          width: 23px;
          height: 23px;
        }
      }
    }
    @media (max-width: 991px) {
      //flex-flow: column;
      font-size: 16px;
      line-height: 1.2;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .des {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    & > div {
      margin-bottom: 5px;
    }
    
    & > section {
      margin-bottom: 40px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
      }
    }
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 15px;
    }
  }

  .instructor-des {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 10px;
      line-height: 15px;
    }

    & > div {
      margin-bottom: 30px;
      @media (max-width: 991px) {
        margin-bottom: 20px;
      }
    }

    li {
      margin-bottom: 20px;
    }

    .normal-ul {
      & > li {
        margin-bottom: 0;
      }
    }

    .second-title {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: #000000;
      margin-bottom: 10px;
      @media (max-width: 991px) {
        font-size: 14px;
      }
    }
  }
  
  .speakers-title {
    font-family: 'Fugaz One';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 76px;
    color: #C3272E;
    margin: 20px auto;
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 41px;
      margin: 10px auto;
    }
  }
  
  .des-title {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    @media (max-width: 991px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  
  .format {
    padding: 10px 10% 40px 15%;
    @media (max-width: 991px) {
      padding: 0px 10% 30px 10%;
    }
  }
  
  .format-des {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;

    @media (max-width: 991px) {
      font-size: 14px;
      line-height: 21px;
    }
    & > div {
      margin-bottom: 20px;
    }
    
    & > span {
      font-weight: 700;
    }
  }
  
  .white-color {
    color: #FFFFFF;
  }
  
  .margin-bottom-20 {
    margin-bottom: 20px;
    @media (max-width: 991px) {
      margin-bottom: 0;
    }
  }
  
  .margin-bottom-50 {
    margin-bottom: 50px;
    @media (max-width: 991px) {
      margin-bottom: 0;
    }
  }
`;

function Whiskeycon() {

  const navigate = useNavigate();

  // useToTop();

  return (
    <Container>

      <section className='banner'>
        <div className='banner-text'>
          {/*<div className='banner-title'>*/}
          {/*  CALL FOR <br/> PRESENTERS*/}
          {/*</div>*/}
          <div className='banner-date'>Date: 9 May 2023</div>
          <div className='banner-date'>Time: 6:30pm</div>
          <div className='margin-bottom-20'></div>
          <div className='banner-date'>Location: Neon Pigeon</div>
          <div className='banner-date white-color'>36 Carpenter St, #01-01, Singapore 059915</div>
          <div className='margin-bottom-50'></div>
          <div className='register-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-2023')}>
            REGISTER NOW
          </div>
        </div>
      </section>

      <section className='about-panel'>
        <div>
          <img className='about-left-img' src={imgs['Group 2932.svg']} />
          <div className='about-left-date'>9 May 2023</div>
          <div className='about-left-location'>Singapore</div>
        </div>
        <div className='about-right'>
          Although the world is experiencing trying times, humanity has persevered <br/> throughout history to overcome challenges and will continue to do so.
          <div></div>
          Life and cyber security must go on. WhiskeyCon is here to help.
          <div></div>
          WhiskeyCon 2023 is an exclusive, one-night only event to be held on <br/> Tuesday, 9 May 2023 in Singapore. Security professionals from all over the <br/> world will gather for an evening of food and drink to share with, and learn from, each other.
        </div>
      </section>

      <section className='schedule-panel'>
        <div className='speakers-title'>Speakers</div>
        <div className='instructor'>
          <div className='instructor-content'>
            <img src={imgs['Nitesh Surana 1.png']} />
            <div>
              <div className='name-title'>
                Nitesh Surana
                <img src={imgs['icons8-twitter-480 1.svg']} onClick={() => window.open('https://twitter.com/_niteshsurana')}/>
              </div>

              <div className='des'>
                <div className='des-title'>Trend Micro</div>
                A threat researcher passionate about cloud vulnerability and security research, threat hunting and modeling, detection engineering and building honeypots to target cloud and container environments.
                <section></section>
                <div className='des-title'>Presentation: A Tale of Credential Leak of a Popular Cloud Threat Actor</div>
                Threat actors abuse misconfigured container components like Docker daemon REST API and perform malicious actions such as exfiltrating sensitive info (i.e. AWS API keys) and remotely controlling victim servers to expand their botnets. This talk with show real-world findings from our deployed container API honeypots and how we observed the threat actors leak credentials of the accounts backing their malicious operations.
              </div>
            </div>
          </div>
        </div>


        <div className='instructor'>
          <div className='instructor-content'>
            <img src={imgs['Varnavas Papaioannou 1.png']} />
            <div>
              <div className='name-title'>
                Varnavas Papaioannou
                <img src={imgs['icons8-twitter-480 1.svg']} onClick={() => window.open('https://twitter.com/_vepe')}/>
                <img src={imgs['linkedin (1).svg']} onClick={() => window.open('https://linkedin.com/in/varnavasp')}/>
              </div>

              <div className='des'>
                <div className='des-title'>Blue Frost Security GmbH</div>
                Security researcher at Blue Frost Security leveraging over a decade of experience in cybersecurity towards vulnerability research and exploit development on various operating systems, with a particular emphasis recently on Windows.
                <div></div>
                <div className='des-title'>Presentation: Taming the Hulk: Attacking the Windows VS Allocator</div>
                In this talk, we will explore Windows segment heap exploitation by abusing the heap metadata to bypass modern mitigations. The main target is going to be the VS allocator, which is one of the primary allocators in the segment heap. Unique characteristics of the attacks are (1) Pure attacks on the segment heap implementation => techniques can be used anywhere segment heap is utilized (eg kernelmode, usermode) regardless of the underlying heap configuration and (2) There is no reliance on the actual overflow data. On the contrary, the attack can sometimes work better when dealing with totally random data. Finally, we also publish a windbg JS extension that exports various internal segment heap structures useful for exploit development/analysis.
              </div>
            </div>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-content'>
            <img src={imgs['123 1.png']} />
            <div>
              <div className='name-title'>
                Brian y koon
              </div>

              <div className='des'>
                <div className='des-title'>Independent Researcher</div>
                PRC based cybersecurity professional with more than 20 years of experience in assisting various law enforcement agencies in China combatting cybercrimes such as money laundering and telecom fraud. Provides advisory and consultation services to private sector enterprises on cybersecurity and related regulations, laws, and compliance requirements.
                <div></div>
                <div className='des-title'>Presentation: Avoiding Pitfalls: The Quagmire of China’s Cybersecurity Regulatory Framework</div>
                In this talk, we will discuss China’s complex cybersecurity regulatory framework on Outbound Data Transfer (ODT) and associated security assessment requirements. Since 2017, China’s OTD laws continue to evolve and, taking effect in June 2023, the Standard Contract measures will be another important consideration to remain compliant while doing business in China. We will discuss what tripwires may bring you Civil Liability, Administrative Liability, or Criminal Liability if you violate China’s regulatory requirements for ODT.
              </div>
            </div>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-content'>
            <img src={imgs['123 4.png']} />
            <div>
              <div className='name-title'>
                Slater Weinstock
              </div>

              <div className='des'>
                <div className='des-title'>Casaba Security</div>
                As a Security Engineer, Slater aids in the development of companies’ and universities’ cybersecurity programs. With a background rooted in chemistry and computer science, he enjoys breaking down complicated systems and identifying new attack vectors and commonly works on codebases written in C, C++, Objective-C, Swift, JavaScript, and Go. While primarily focusing on security reviews on iOS and macOS -based applications, Slater also works on systems programming, SDL, dynamic analysis, attack surface reviews, cryptography and mobile application security.
                <div></div>
                <div className='des-title'>Presentation: WebAuthn</div>
                We will discuss WebAuthn and how passwordless authentication works. We will then discuss methods to protect against cyber and phishing-related attacks. We will cover WebAuthn Registration and Authentication Ceremonies, and discuss the critical differences between Passkeys vs Passwords, show the exchanges between the local static key pair, local ephemeral key pair, and pre-shared symmetric key.
              </div>
            </div>
          </div>
        </div>

      </section>

      <section className='format'>
        <div className='speakers-title'>Speakers</div>
        <div className='format-des'>
          Following the above guest speakers (and a few surprise more), the floor will be opened for the WhiskeyTalk session. Anyone will be allowed to speak about security-related topics.
          <div></div>
          <span>Price of admission to speak?</span> Drink 2 shots of whiskey for five minutes of speaking time. Need another 5 minutes? Drink 2 more shots. Anyone can speak, as long as you drink.
          <div></div>
          Ticket price includes 3-hours of free flow house Whiskey, Gin & Vodka (with mixers), Classic Red and White Wine, Peroni Beer, Soft Drinks (Coke, Coke Zero, Sprite, Ginger Ale, Tonic, Soda), and 3-hour free flow of amazing food as provided exclusively for this private event by the Neon Pigeon, Singapore.
        </div>
      </section>

    </Container>
  )
}

export default Whiskeycon;
