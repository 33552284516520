import styled from 'styled-components';
import importAllImg from "../helpers/import-all-imgs";
import { useNavigate } from "react-router-dom";
import {useState} from "react";

const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));
const Container = styled.header`
  

    display: flex;
    background: #000000;
    height: 100px;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    border-radius: 0px 0px 40px 40px;

  @media (max-width: 991px) {
      display: none;
    }
    & > img {
      margin-left: 15%;
    }

    .links {
      margin-left: auto;
      margin-right: 10%;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1em;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      column-gap: 50px;
      & > span, & > a {
        //margin-left: 34px;
        cursor: pointer;
        color: #FFFFFF;
        text-decoration: none;
        &.special {
          color: #FFC600;
        }
      }
    }
`;

const MobileContainer = styled.header`
  display: none;
  padding: 15px 10%;
  background: #000000;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  height: 70px;
  width: 100%;
  z-index: 3;

  @media (max-width: 991px) {
    display: flex;
  }
`;

const MobileContainer2 = styled.header`
  display: none;
  background: #FFFFFF;

  position: fixed;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  z-index: 3;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10%;
    background: #000000;
    border-radius: 0px 0px 40px 40px;
    & > img {
      z-index: 2;
    }
  }
  .links {
    display: flex;
    flex-flow: column;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.1em;
    color: #000000;
    padding: 30px 10%;
    z-index: 2;
    & > a, & > span {
      margin-bottom: 30px;
      text-decoration: none;
      color: #000000;
      &.special {
        color: #C3272E;
      }
    }
  }
  @media (max-width: 991px) {
    display: block;
  }
`;

function Header() {

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(i => !i);

  const smoothScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Container>
        <img onClick={() => navigate('/')} src={imgs['Group (1).svg']} />
        <div className='links'>
          {/*<span onClick={() => navigate('/')}>HOME</span>*/}
          {/*<a href='#about'>ABOUT</a>*/}
          {/*<a href='#schedule'>SCHEDULE</a>*/}
          {/*<span onClick={() => navigate('/whiskeycon')}>WHISKEYCON</span>*/}
          {/*<span style={{color: '#C3272E'}} onClick={() => navigate('/')}>WHISKEYCON</span>*/}
          <a className='special' target='_blank' href='https://sghack.regfox.com/sghack-reg' >SGHACK CONFERENCE 2024</a>
          <a onClick={() => smoothScroll('about')}>ABOUT</a>
          <a onClick={() => smoothScroll('up-events')}>UPCOMING EVENTS</a>
          <a onClick={() => smoothScroll('touch')}>GET IN TOUCH</a>
        </div>
      </Container>
      <MobileContainer style={{display: show ? 'none' : ''}}>
        <img src={imgs['Group (1).svg']} />
        <img src={imgs['Group 2933.svg']} onClick={toggleShow} />
      </MobileContainer>

      <MobileContainer2 style={{display: show ? '' : 'none'}}>
        <div className='title'>
          <img src={imgs['Group (1).svg']} />
          <img src={imgs['Group 2913.svg']} onClick={toggleShow} />
        </div>
        <div className='links'>
          {/*<span onClick={() => {setShow(false);navigate('/')}}>HOME</span>*/}
          {/*<a onClick={() => setShow(false)} href='#about'>ABOUT</a>*/}
          {/*<a onClick={() => setShow(false)} href='#schedule'>SCHEDULE</a>*/}
          {/*<span onClick={() => {setShow(false); navigate('/whiskeycon')}}>WHISKEYCON</span>*/}
          {/*<span onClick={() => {setShow(false);navigate('/')}}>WHISKEYCON</span>*/}
          <a target='_blank' href='https://sghack.regfox.com/sghack-reg' className='special' onClick={() => setShow(false) } >SGHACK CONFERENCE 2024</a>
          <a onClick={() => { setShow(false); smoothScroll('about') }}>ABOUT</a>
          <a onClick={() => { setShow(false); smoothScroll('up-events') }}>UPCOMING EVENTS</a>
          <a onClick={() => { setShow(false); smoothScroll('touch') }}>GET IN TOUCH</a>
        </div>
      </MobileContainer2>

    </>

  )
}

export default Header;
