import importAllImg from "../helpers/import-all-imgs";
import useToTop from "../helpers/useToTop";
import Container from "../components/CourseContainer";

const imgs = importAllImg(require.context('../img', false, /\.(png|jpe?g|svg)$/));


function Course4() {

  useToTop();

  return (
    <Container>
      <div className='banner'>
        <div className='banner-text'>
          <div className='title'>Android Security 101</div>
          <div className='date'>8-9 May 2023</div>
          <div className='register-btn' onClick={() => window.open('https://sghack.regfox.com/sghack-2023')}>
            REGISTER NOW
          </div>
        </div>
      </div>

      <div className='main-content'>

        <div className='instructor'>
          <div className='instructor-title'>instructor</div>
          <div className='instructor-content'>
            <img src={imgs['Eric Le Guevel 2.png']} />
            <div>
              <div className='name-title'>
                Eric Le Guevel
                <img src={imgs['image 6.svg']} onClick={() => window.open('https://infosec.exchange/@haoris')}/>
              </div>

              <div className='des'>
                With over 15 years of industry expertise, starting first as a developer and then as a system administrator, Eric has had the chance to work on reverse engineering of industrial hardware and software. Since joining the Quarkslab mobile team three years ago, Eric has been focused on auditing Android applications and firmware, and tooling and fuzzing techniques against Android targets.
              </div>
            </div>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>COURSE DESCRIPTION</div>

          <div className='instructor-des'>

            This course is an introduction to Android application analysis and will present the functioning and the internal structure of standard Android applications, the methodology of static analysis, dynamic analysis, as well as the modification of the behaviour of an application in a static way (patching) and dynamic way (hooking). The practical part will allow to get started with some of the analysis tools that constitute the state of the art at the moment


            <div></div>
            <div className='second-title'>Key Learning Objectives:</div>

            <ul className='normal-ul'>
              <li>Be able to audit an Android application through static and dynamic analysis, modify the behavior of an application.</li>
              <li>Familiarize with some of the state-of-the-art Android analysis tools. </li>
              <li>Understand the architecture and the internal functioning of an Android application on the bytecode and native part.</li>
              <li>Understand some of the Android system mechanisms used by the applications. </li>
            </ul>

            <div className='second-title'>Who Should Attend:</div>

            People who want to discover how Android applications work and reverse engineering methods, as well as static and dynamic analysis tools (Jadx, apktool, Frida, IDA).
            <div></div>
            <div className='second-title'>Pre-requisites</div>

            <ul className='normal-ul'>
              <div>Python/Java basics </div>
              <div>Basic knowledge of at least one common assembly language (x86 / x86_64 / arm32 / arm64) </div>
              <div>Basic knowledge of at least one static binary analysis tool (IDA / Ghidra / BinaryNinja / r2) </div>
            </ul>

            <div className='second-title'>What students should bring?</div>

            <ul className='normal-ul'>
              <li> A laptop with at least 8GB of RAM (16GB recommended)</li>
              <li>Software Requirements:
                <div className='sub-list'>
                  o Even though training can be theoretically done on most common OS, a Linux distribution is highly recommended. <br/>
                  o A static analysis tool that can handle arm32 / arm64 architecture (IDA is highly recommended) <br/>
                  o Jadx (https://github.com/skylot/jadx) <br/>
                  o apktool (https://github.com/iBotPeaches/Apktool) <br/>
                  o Android Studio (https://developer.android.com/studio) (or a least adb + a working **Google** Android emulator) <br/>
                  o The other prerequisites will be installed on site. <br/>
                  o A VirtualBox appliance can be provided as a fallback solution if some students encounter issues with setup on their host.
                </div>
              </li>

            </ul>
          </div>
        </div>

        <div className='instructor'>
          <div className='instructor-title'>Course Agenda:</div>
          <div className='day-content'>
            <div className='day-title'>
              DAY 1
            </div>
            <div className='day-text'>

              <div className='day-text-title'>Theory:</div>
              <ul>
                <li>Introduction to the Android system and development tools </li>
                <li>Structure and important elements of an APK file (Manifest, Resources, etc.) </li>
                <li>Dalvik bytecode and its intermediate representation </li>
                <li>Software components of an application (Activities, Services, etc.) </li>
                <li>Application entry points </li>
                <li>High-level Android IPC mechanisms (Messenger, Binder) </li>
              </ul>


              <div className='day-text-title'>Practice:</div>
              <ul>
                <li>Additional requirements setup</li>
                <li>Bytecode analysis</li>
                <li>                Go through various analysis challenges of an educational Android application with Jadx
                </li>
                <li>                Changing the behavior of an application: APK patching with apktool
                </li>
              </ul>



            </div>
          </div>

          <div className='day-content'>
            <div className='day-title'>
              DAY 2
            </div>
            <div className='day-text'>


              <div className='day-text-title'>Theory:</div>
              <ul>
                <li>              Application native components (JNI, entry points, etc.)
                </li>
                <li>              Dynamic code loading (ClassLoaders, reflection)
                </li>
                <li>              Dynamic analysis of Android applications
                </li>
                <li>              Android Runtimes
                </li>
              </ul>



              <div className='day-text-title'>Practice:</div>
              <ul>
                <li>              Static analysis of JNI native libraries with IDA
                </li>
                <li>              Changing the behavior of an application: Frida instrumentation
                </li>
                <li>              Go through various dynamic analysis challenges with Frida
                </li>
              </ul>


              <div className='day-text-title'>              Optional (depending on the level and expectations of the participants):
              </div>
              <ul>
                <li>              Common Android application vulnerabilities
                </li>
                <li>              Application network flow analysis
                </li>
                <li>              Go through basic obfuscation with r8
                </li>
                <li>              Instrumentation through library injection
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Course4;
